import React from 'react'
import { HiArrowNarrowRight} from 'react-icons/hi'
import Github from '../assets/github (3).png'
import Insta from '../assets/insta.png'
import Vsco from '../assets/vsco.png'
import linkedin from '../assets/linkedin.png'
import Twitter from '../assets/twitter.png'
import { Link } from 'react-scroll'
import {TypeAnimation} from 'react-type-animation';
const Hero = () => {
  return (
    <div name="home" className='w-full h-screen bg-[#0a192f]'>
        
        <div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full'>
            <p className='text-purple-500 text-xl'>Hi my name is</p>
            <h1 className='text-4xl sm:text-7xl font-bold text-[#ccd6f6]' >Evan Kagarise</h1>
            <h2 className='text-4xl sm:text7xl font-bold text-[#8892b0] pt-1'>I'm a <span> </span> 
             <TypeAnimation 
            sequence={[
              'Developer',
              2000,
              'Designer',
              2000,
              'Creator',
              2000,
            ]}
            speed={50}
            className='text-purple-500'
            wrapper='span' 
            repeat={Infinity}
            />
            </h2>
           
            <p className='text-[#8892b0] py-4 max-w-[700px]'>  I’m a developer specializing in building (and occasionally
          designing) exceptional digital experiences. Currently, I’m focused on
          building responsive full-stack web applications.</p>
          
          <div className='flex h-12'>
            <a href="https://github.com/evankagarise"><img src={Github} /> </a>
            
            <img src={Insta} />
            <img className="w-12" src={Vsco} />
            
            <a href="https://www.linkedin.com/in/evankagarise/"><img src={linkedin} /> </a>
            <img src={Twitter} />
          </div>
          <div className='flex gap-4 pt-3'>
          
            <Link to="work" smooth={true} duration={500} > <button className='text-white border-2 px-6 py-3 my-2 flex items-center hover:bg-purple-500 hover:border-purple-500' href="">My Work 
            <span className='group-hover:rotate-90 duration-300'>
                 <HiArrowNarrowRight className='ml-3' />
            </span>
            </button></Link>
          </div>
        </div>
    </div>
  )
}

export default Hero