import React from 'react'
import { useState } from 'react'
import {FaBars, FaTimes} from 'react-icons/fa'
import {Link} from 'react-scroll'

const Navbar = () => {
    const [nav, setNav] = useState(false)
    const handleClick = () => setNav(!nav)

  return (
    <div className='fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#0a192f]'>
        <div>
            <h1 className='text-4xl text-white'>Evan Kagarise</h1>
        </div>

        <div className='hidden md:flex'>
             <ul className='flex text-white'>
              
             <li  className='hover:text-purple-500'>
              <Link to="home" smooth={true} duration={500} >Home </Link>
              
             </li>
             <li className='hover:text-purple-500'><Link to="about" smooth={true} duration={500} >About </Link></li>
             <li className='hover:text-purple-500'><Link to="skills" smooth={true} duration={500} >Skills </Link></li>
              <li  className='hover:text-purple-500'><Link to="work" smooth={true} duration={500} >Projects </Link></li>
              <li  className='hover:text-purple-500'><Link to="contact" smooth={true} duration={500} >Contact </Link></li>
              
            
            </ul>
        </div>
       
       <div onClick={handleClick} className='md:hidden z-10 text-white'>
        {!nav ? <FaBars /> : <FaTimes />}
       </div>

       <ul className={!nav ? 'hidden' : 'absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center'}>
             <li className='py-6 text-white text-4xl'><Link to="home" smooth={true} duration={500} >Home </Link></li>
             <li className='py-6 text-white text-4xl'><Link to="about" smooth={true} duration={500} >About </Link></li>
             <li className='py-6 text-white text-4xl'><Link to="skills" smooth={true} duration={500} >Skills </Link></li>
              <li className='py-6 text-white text-4xl'><Link to="work" smooth={true} duration={500} >Projects </Link></li>
              <li className='py-6 text-white text-4xl'><Link to="contact" smooth={true} duration={500} >Contact </Link></li>
             
       </ul>



        <div className='hidden'></div>


    </div>
  )
}

export default Navbar